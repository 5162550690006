import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { BusyService } from '../../core/services/busy.service';
import { CORE_ROUTE_PATH } from '../core.definition';


@Injectable()
export class NavigationService {

   constructor(
      private busyService: BusyService,
      private router: Router
   ) { }

   public navigateToHomeAsync(extras?: NavigationExtras): Promise<boolean> {
      this.busyService.clearBusy();
      return this.router.navigate([CORE_ROUTE_PATH.HOME], extras);
   }

   public navigateToSettings(extras?: NavigationExtras): Promise<boolean> {
      this.busyService.clearBusy();
      return this.router.navigate([CORE_ROUTE_PATH.SETTINGS], extras);
   }
}
