import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { NotificationBarService } from '../../services/notification-bar.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'asc-notification-bar',
    templateUrl: './notification-bar.component.html',
    styleUrls: ['./notification-bar.component.scss'],
    standalone: false
})
export class AscNotificationBarComponent implements OnInit, OnDestroy {

   public message?: string;
   private subscriptions = new Subscription();

   constructor(
      private hostRef: ElementRef,
      private notificationBarService: NotificationBarService
   ) { }

   ngOnInit() {
      this.subscriptions.add(this.notificationBarService.onMessageChanged.subscribe(message => {
         if (message) {
            this.show(message);
         }
         else {
            this.hide();
         }
      }));

      if (this.notificationBarService.message) {
         this.show(this.notificationBarService.message);
      }
      else {
         this.hide();
      }
   }

   ngOnDestroy() {
      this.subscriptions.unsubscribe();
   }

   public get isVisible() {
      return (this.hostRef.nativeElement as HTMLElement).style.display !== 'none';
   }

   public hide() {
      (this.hostRef.nativeElement as HTMLElement).style.display = 'none';
   }

   private show(message: string) {
      this.message = message;
      (this.hostRef.nativeElement as HTMLElement).style.display = 'flex';
   }
}
