export { }

declare global {
   interface Array<T> {
      distinctBy<T, TKey>(fnGetKey: (e: T) => TKey): Array<T>;
      groupBy<T, TKey>(fnGetKey: (e: T) => TKey): Array<{ key: TKey, items: T[] }>;
      sortBy<T, TKey>(fnGetKey: (e: T) => TKey): Array<T>;
   }
}


if (!Array.prototype.distinctBy) {
   Array.prototype.distinctBy = function <T, TKey>(this: T[], fnGetKey: (e: T) => TKey): T[] {
      let result: T[] = [];
      this.forEach(e => {
         const key = fnGetKey(e);
         if (result.findIndex(r => fnGetKey(r) === key) === -1) {
            result.push(e);
         }
      });

      return result;
   };
}

if (!Array.prototype.groupBy) {
   Array.prototype.groupBy = function <T, TKey>(this: T[], fnGetKey: (e: T) => TKey): { key: TKey, items: T[] }[] {
      const result: { key: TKey, items: T[] }[] = [];
      this.forEach(e => {
         const key = fnGetKey(e);

         let group = result.find(g => JSON.stringify(g.key) === JSON.stringify(key));
         if (!group) {
            group = { key: key, items: [] };
            result.push(group);
         }

         group.items.push(e);
      });

      return result;
   };
}

if (!Array.prototype.sortBy) {
   Array.prototype.sortBy = function <T, TKey>(this: T[], fnGetKey: (e: T) => TKey): T[] {
      return this.sort((e1, e2) => {
         const key1 = fnGetKey(e1);
         const key2 = fnGetKey(e2);
         return (key1 === key2) ? 0 : ((key1 > key2) ? 1 : -1);
      });
   };
}
