import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CORE_NAVIGATION_ITEMS, CORE_ROUTE_PATH } from './core.definition';

// Components
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SettingsComponent } from './views/settings/settings.component';
import { NavigationContext } from './model/navigation-context';


export const CORE_ROUTES: Routes = [
   {
      path: CORE_ROUTE_PATH.HOME,
      data: {
         navigationContext: NavigationContext.forFeatureLink(CORE_NAVIGATION_ITEMS.HOME)
      },
      component: DashboardComponent
   },
   {
      path: CORE_ROUTE_PATH.SETTINGS,
      data: {
         navigationContext: NavigationContext.forFeatureLink(CORE_NAVIGATION_ITEMS.SETTINGS)
      },
      component: SettingsComponent
   }
];

export const CORE_ROUTER_MODULE: ModuleWithProviders<RouterModule> = RouterModule.forRoot(CORE_ROUTES, {
   // enableTracing: true,
   initialNavigation: 'disabled'
});
