import { Component, EventEmitter, Output } from '@angular/core';
import { ModuleManagerService } from '../../services/module-manager.service';
import { INavigationItem } from '../../model/i-navigation-item';
import { ModuleEntryPointDefinition } from '../../model/module-entry-point-definition';

@Component({
    selector: 'asc-settings-menu',
    templateUrl: './settings-menu.component.html',
    styleUrls: ['./settings-menu.component.scss'],
    standalone: false
})
export class SettingsMenuComponent {
   @Output() public itemSelected = new EventEmitter();
   public activeEntryPoint?: ModuleEntryPointDefinition;

   constructor(
      private moduleManagerService: ModuleManagerService
   ) { }

   public get settingsNavigationItems(): INavigationItem[] {
      return this.moduleManagerService.settingsNavigationItems;
   }

   public navigateTo(entryPoint: ModuleEntryPointDefinition) {
      this.activeEntryPoint = entryPoint;
      this.itemSelected.emit();
      entryPoint?.navigate();
   }

   public isActive(navigationItem: INavigationItem): boolean {
      return this.moduleManagerService.isSettingsNavigationItemActive(navigationItem.id);
   }
}
