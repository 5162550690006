import { Component, Input, OnInit, ElementRef } from '@angular/core';

@Component({
    selector: 'asc-module-icon',
    templateUrl: './module-icon.component.html',
    styleUrls: ['./module-icon.component.scss'],
    standalone: false
})
export class AscModuleIconComponent implements OnInit {
   @Input() public size?: string;
   @Input() public primary?: string;
   @Input() public secondary?: string;

   constructor(private host: ElementRef) { }

   ngOnInit() {
      if (this.secondary) {
         const h: HTMLElement = this.host.nativeElement;
         h.classList.add('asc-module-icon-composed');
      }
   }
}
